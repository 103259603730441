import React from "react";

import type { Product } from "resources/AudiotekaApi";

import { Teaser } from "./teaser";
import { TeaserFooter } from "./teaser-footer";
import { PlayButton } from "./play-button";

interface Props {
  product: Product;
  colorless?: boolean;
  lazyLoading?: boolean;
  onClick?: () => void;
}

export function PricelessTeaser({ product, colorless, lazyLoading, onClick }: Props) {
  return (
    <Teaser.Container colorless={colorless}>
      <Teaser.Link onClick={onClick} product={product}>
        <Teaser.Cover alt={product.name} lazyLoading={lazyLoading} url={product.image_url} />
        <Teaser.Title title={product.name} />
        <Teaser.Author author={product.description} />
        <TeaserFooter.CycleOrRatingBadge product={product} />
        <TeaserFooter.ClubBadge product={product} />
      </Teaser.Link>
      <PlayButton slug={product.slug} />
    </Teaser.Container>
  );
}
